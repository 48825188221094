<template>
  <v-layout row wrap>
    <v-flex xs6>
      <v-text-field label="Amount" v-model="amountText" required @focus="focused=true" @blur="focused=false; fixAmountText()" @keypress="amountAsInteger && isNumber($event)"></v-text-field>
    </v-flex>
    <v-flex xs6>
      <v-text-field v-if="anyCurrency" label="Currency" v-model="value.currency"></v-text-field>
      <k-currency-selector v-if="!anyCurrency" label="Currency" v-model="value.currency" required ></k-currency-selector>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: Object,
    amountAsInteger: Boolean,
    anyCurrency: Boolean,
    label: String,
  },
  data() {
    return {
      amountText: '',
      focused: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.focused) {
          this.fixAmountText();
        }
      },
    },
    amountText: function watcher() {
      const f = parseFloat(this.amountText);
      let exponent = 1;
      if (!this.amountAsInteger) {
        const c = this.$global.env.currenciesByID[this.value.currency];
        for (let i = 0; i < c.decimals; i += 1) {
          exponent *= 10;
        }
      }
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(f)) {
        this.value.amount = Math.round(f * exponent);
      }
    },
  },
  methods: {
    fixAmountText() {
      if (this.amountAsInteger) {
        return;
      }
      const c = this.$global.env.currenciesByID[this.value.currency];
      let exponent = 1;
      for (let i = 0; i < c.decimals; i += 1) {
        exponent *= 10;
      }
      this.amountText = (this.value.amount / exponent).toFixed(c.decimals);
    },
    isNumber(e) {
      const charCode = (e.which) ? e.which : e.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode === 46) {
        e.preventDefault();
      }

      return true;
    },
  },
  computed: {
    currencies() {
      if (!this.$global.currencies) {
        return [];
      }
      return this.$global.currencies.map((c) => ({ value: c.id, text: c.name }));
    },
  },
};
</script>
